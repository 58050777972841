<template>
  <div class="card">
    <div class="card-body" v-if="!toggle">
      <span class="card-text">
        <span
          class="mx-1 pointer float-right"
          @click="toggle = !toggle">
          <fa icon="pencil-alt" class="text-warning"></fa>
        </span>
        <div class="">
          {{item.name}}
          <span v-if="item.unit">({{item.unit.name}})</span>
        </div>

        <div
          class="mt-2"
          v-if="sourceItems.length > 0">
          <small>
            นำเข้าสินค้า:
            <ul class="my-0">
              <li
                v-for="child in sourceItems"
                :key="child.id">
                <div class="text-warning">สินค้า: {{child.inventory.code}} ({{child.inventory.name}})</div>
                <div class="">
                  <span class="text-success">
                    จำนวน: {{child.qty}}
                  </span>
                </div>
              </li>
            </ul>
          </small>
        </div>

        <div
          class="mt-2"
          v-if="sourceExpenses.length > 0">
          <small>
            ต้นทุนสินค้า:
            <ul class="my-0">
              <li
                v-for="child in sourceExpenses"
                :key="child.id">
                <div
                  class="text-info"
                  v-for="ch in child.junctionChildren.filter(x => x.type === 'costAccount')"
                  :key="ch.id">
                  กลุ่มบัญชี: {{ch.name}}
                </div>
                <div
                  v-if="child.account"
                  class="text-info">
                  บัญชี: {{child.account.code}} ({{child.account.name}})
                </div>
              </li>
            </ul>
          </small>
        </div>

        <div
          class="mt-2"
          v-if="transferItems.length > 0">
          <small>
            ไปยังสินค้า:
            <ul class="my-0">
              <li
                v-for="child in transferItems"
                :key="child.id">
                <div class="text-warning">สินค้า: {{child.inventory.code}} ({{child.inventory.name}})</div>
                <div
                  class="text-info"
                  v-for="ch in child.junctionChildren.filter(x => x.type === 'itemAccount')"
                  :key="ch.id">
                  กลุ่มบัญชี: {{ch.name}}
                </div>
                <div
                  v-if="child.account"
                  class="text-info">
                  บัญชี: {{child.account.code}} ({{child.account.name}})
                </div>
                <div class="">
                  <span class="text-success">
                    จำนวน: {{child.qty}}
                  </span>
                  สัดส่วน: {{child.amountRatio}}
                </div>
              </li>
            </ul>
          </small>
        </div>

        <div
          class="mt-2"
          v-if="transferExpenses.length > 0">
          <small>
            ไปยังค่าใช้จ่าย:
            <ul class="my-0">
              <li
                v-for="child in transferExpenses"
                :key="child.id">
                <div
                  class="text-info"
                  v-for="ch in child.junctionChildren.filter(x => x.type === 'expenseAccount')"
                  :key="ch.id">
                  กลุ่มบัญชี: {{ch.name}}
                </div>
                <div
                  v-if="child.account"
                  class="text-info">
                  บัญชี: {{child.account.code}} ({{child.account.name}})
                </div>
                <div class="">สัดส่วน: {{child.amountRatio}}</div>
              </li>
            </ul>
          </small>
        </div>
      </span>
    </div>

    <div class="card-body" v-if="toggle">
      <div class="card-text">
        <div class="form-row">
          <sgv-input-text
            label="ชื่อ"
            class="col-12 col-sm-8"
            v-model="formData.name"
            :validations="[
              {text: 'required!', value: $v.formData.name.$dirty && !$v.formData.name.required}
            ]">
          </sgv-input-text>

          <DocConfigFormUnit
            class="col-12 col-sm-4"
            select="id"
            label="หน่วย"
            :docType="docType"
            :templateType="templateType"
            v-model="formData.unitId"
            :validations="[
              {text: 'required!', value: $v.formData.unitId.$dirty && $v.formData.unitId.$error}
            ]">
          </DocConfigFormUnit>
        </div>

        <template v-if="item.id">
          <ul class="nav nav-pills justify-content-center mb-3">
            <li
              class="nav-item"
              v-for="tab in tabs"
              :key="tab.value"
              @click.prevent="selectedTab = tab.value">
              <a
                class="nav-link"
                href="#"
                :class="{'active': selectedTab === tab.value}">
                {{tab.text}}
              </a>
            </li>
          </ul>

          <div v-if="selectedTab === 'sourceItem'">
            <div class="form-row">
              <DocConfigFormInventory
                v-if="menu.inventoryTypes.length > 0"
                class="col-12 col-sm-9"
                select="id"
                :types="menu.inventoryTypes"
                :docType="docType"
                :templateType="templateType"
                v-model="sourceItemFormData.inventoryId"
                :validations="[
                  {text: 'required!', value: $v.sourceItemFormData.inventoryId.$dirty && $v.sourceItemFormData.inventoryId.$error}
                ]">
              </DocConfigFormInventory>

              <sgv-input-number
                label="จำนวน"
                class="col-6 col-sm-3"
                placeholder="จำนวน"
                v-model="sourceItemFormData.qty"
                :precision="2"
                :validations="[
                  {text: 'required!', value: $v.sourceItemFormData.qty.$dirty && !$v.sourceItemFormData.qty.required}
                ]">
              </sgv-input-number>
            </div>

            <button
              type="button"
              class="btn btn-success mb-3"
              @click="addSourceItem">
              เพิ่ม
            </button>

            <div
              v-if="sourceItems.length > 0"
              class="mb-3">
              <small>
                <ul class="my-0">
                  <li
                    v-for="child in sourceItems"
                    :key="child.id">
                    <span
                      @click="destroyData(child.id, item.id)"
                      class="pointer mr-1">
                      <fa icon="trash" class="text-danger"></fa>
                    </span>
                    <div class="">สินค้า: {{child.inventory.code}} ({{child.inventory.name}})</div>
                    <div class="">
                      จำนวน: {{child.qty}}
                    </div>
                  </li>
                </ul>
              </small>
            </div>
          </div>

          <div v-if="selectedTab === 'sourceExpense'">
            <div class="form-row">
              <DocConfigFormCostAccount
                label="กลุ่มบัญชีต้นทุนสินค้า"
                placeholder="เลือกกลุ่มบัญชี..."
                :templateType="templateType"
                :docType="docType"
                class="col-12 col-sm-6"
                select="id"
                v-model="sourceExpenseFormData.costAccountId"
                :validations="[
                  {text: 'required!', value: $v.sourceExpenseFormData.costAccountId.$dirty && $v.sourceExpenseFormData.costAccountId.$error}
                ]">
              </DocConfigFormCostAccount>

              <DocConfigFormAccount
                label="บัญชี"
                class="col-12 col-sm-6"
                select="id"
                :docType="docType"
                :templateType="templateType"
                v-model="sourceExpenseFormData.accountId">
              </DocConfigFormAccount>
            </div>

            <button
              type="button"
              class="btn btn-success mb-3"
              @click="addSourceExpense">
              เพิ่ม
            </button>

            <div
              v-if="sourceExpenses.length > 0"
              class="mb-3">
              <small>
                <ul class="my-0">
                  <li
                    v-for="child in sourceExpenses"
                    :key="child.id">
                    <span
                      @click="destroyData(child.id, item.id)"
                      class="pointer mr-1">
                      <fa icon="trash" class="text-danger"></fa>
                    </span>{{child.id}}
                    <div
                      class="text-info"
                      v-for="ch in child.junctionChildren.filter(x => x.type === 'costAccount')"
                      :key="ch.id">
                      กลุ่มบัญชี: {{ch.name}}
                    </div>
                    <div v-if="child.account">
                      บัญชี: {{child.account.code}} ({{child.account.name}})
                    </div>
                  </li>
                </ul>
              </small>
            </div>
          </div>

          <div v-if="selectedTab === 'transferItem'">
            <div class="form-row">
              <DocConfigFormInventory
                v-if="menu.inventoryTypes.length > 0"
                class="col-12"
                select="id"
                :types="menu.inventoryTypes"
                :docType="docType"
                :templateType="templateType"
                v-model="transferItemFormData.inventoryId"
                :validations="[
                  {text: 'required!', value: $v.transferItemFormData.inventoryId.$dirty && $v.transferItemFormData.inventoryId.$error}
                ]">
              </DocConfigFormInventory>

              <DocConfigFormItemAccount
                label="กลุ่มบัญชีสินค้า"
                placeholder="เลือกกลุ่มบัญชี..."
                :templateType="templateType"
                :docType="docType"
                class="col-12 col-sm-6"
                select="id"
                v-model="transferItemFormData.itemAccountId"
                :validations="[
                  {text: 'required!', value: $v.transferItemFormData.itemAccountId.$dirty && $v.transferItemFormData.itemAccountId.$error}
                ]">
              </DocConfigFormItemAccount>

              <DocConfigFormAccount
                label="บัญชี"
                class="col-12 col-sm-6"
                select="id"
                :docType="docType"
                :templateType="templateType"
                v-model="transferItemFormData.accountId">
              </DocConfigFormAccount>

              <sgv-input-number
                label="จำนวน"
                class="col-6 col-sm-3"
                placeholder="จำนวน"
                v-model="transferItemFormData.qty"
                :precision="2"
                :validations="[
                  {text: 'required!', value: $v.transferItemFormData.qty.$dirty && !$v.transferItemFormData.qty.required}
                ]">
              </sgv-input-number>

              <sgv-input-number
                label="สัดส่วนทางบัญชี"
                class="col-6 col-sm-3"
                placeholder="0.00 ถึง 1.00"
                v-model="transferItemFormData.amountRatio"
                :precision="2"
                :validations="[
                  {text: 'required!', value: $v.transferItemFormData.amountRatio.$dirty && !$v.transferItemFormData.amountRatio.required},
                  {text: 'จำนวนมากกว่า 0', value: $v.transferItemFormData.amountRatio.$dirty && !$v.transferItemFormData.amountRatio.minValue}
                ]">
              </sgv-input-number>
            </div>

            <button
              type="button"
              class="btn btn-success mb-3"
              @click="addTransferItem">
              เพิ่ม
            </button>

            <div
              v-if="transferItems.length > 0"
              class="mb-3">
              <small>
                <ul class="my-0">
                  <li
                    v-for="child in transferItems"
                    :key="child.id">
                    <span
                      @click="destroyData(child.id, item.id)"
                      class="pointer mr-1">
                      <fa icon="trash" class="text-danger"></fa>
                    </span>
                    <div class="">สินค้า: {{child.inventory.code}} ({{child.inventory.name}})</div>
                    <div
                      class="text-info"
                      v-for="ch in child.junctionChildren.filter(x => x.type === 'itemAccount')"
                      :key="ch.id">
                      กลุ่มบัญชี: {{ch.name}}
                    </div>
                    <div v-if="child.account">
                      บัญชี: {{child.account.code}} ({{child.account.name}})
                    </div>
                    <div class="">
                      จำนวน: {{child.qty}}
                      สัดส่วน: {{child.amountRatio}}
                    </div>
                  </li>
                </ul>
              </small>
            </div>
          </div>

          <div v-if="selectedTab === 'transferExpense'">
            <div class="form-row">
              <DocConfigFormExpenseAccount
                label="กลุ่มบัญชีค่าใช้จ่าย"
                placeholder="เลือกกลุ่มบัญชี..."
                :templateType="templateType"
                :docType="docType"
                class="col-12 col-sm-6"
                select="id"
                v-model="transferExpenseFormData.expenseAccountId"
                :validations="[
                  {text: 'required!', value: $v.transferExpenseFormData.expenseAccountId.$dirty && $v.transferExpenseFormData.expenseAccountId.$error}
                ]">
              </DocConfigFormExpenseAccount>

              <DocConfigFormAccount
                label="บัญชี"
                class="col-12 col-sm-6"
                select="id"
                :docType="docType"
                :templateType="templateType"
                v-model="transferExpenseFormData.accountId">
              </DocConfigFormAccount>

              <sgv-input-number
                label="สัดส่วนทางบัญชี"
                class="col-6 col-sm-3"
                placeholder="0.00 ถึง 1.00"
                v-model="transferExpenseFormData.amountRatio"
                :precision="2"
                :validations="[
                  {text: 'required!', value: $v.transferExpenseFormData.amountRatio.$dirty && !$v.transferExpenseFormData.amountRatio.required},
                  {text: 'จำนวนมากกว่า 0', value: $v.transferExpenseFormData.amountRatio.$dirty && !$v.transferExpenseFormData.amountRatio.minValue}
                ]">
              </sgv-input-number>
            </div>

            <button
              type="button"
              class="btn btn-success mb-3"
              @click="addTransferExpense">
              เพิ่ม
            </button>

            <div
              v-if="transferExpenses.length > 0"
              class="mb-3">
              <small>
                <ul class="my-0">
                  <li
                    v-for="child in transferExpenses"
                    :key="child.id">
                    <span
                      @click="destroyData(child.id, item.id)"
                      class="pointer mr-1">
                      <fa icon="trash" class="text-danger"></fa>
                    </span>
                    <div
                      class="text-info"
                      v-for="ch in child.junctionChildren.filter(x => x.type === 'expenseAccount')"
                      :key="ch.id">
                      กลุ่มบัญชี: {{ch.name}}
                    </div>
                    <div v-if="child.account">
                      บัญชี: {{child.account.code}} ({{child.account.name}})
                    </div>
                    <div class="">สัดส่วน: {{child.amountRatio}}</div>
                  </li>
                </ul>
              </small>
            </div>
          </div>
        </template>

        <button
          v-if="isForm"
          type="button"
          class="btn btn-success"
          @click="updateData">
          เพิ่ม
        </button>

        <button
          v-if="!isForm"
          type="button"
          class="btn btn-warning"
          @click="updateData">
          แก้ไข
        </button>

        <button
          v-if="toggle"
          type="button"
          class="btn btn-primary ml-2"
          @click="cancelData">
          ยกเลิก
        </button>

        <button
          v-if="!isForm"
          type="button"
          class="btn btn-danger ml-2 float-right"
          @click="destroyData(item.id)">
          ลบ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DocConfigFormUnit from '@/views/doc_core/components/DocConfigFormUnit'
import DocConfigFormInventory from '@/views/doc_core/components/DocConfigFormInventory'
import DocConfigFormAccount from '@/views/doc_core/components/DocConfigFormAccount'
import DocConfigFormItemAccount from './DocConfigFormItemAccount'
import DocConfigFormCostAccount from './DocConfigFormCostAccount'
import DocConfigFormExpenseAccount from './DocConfigFormExpenseAccount'


export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    isForm: {
      type: Boolean,
      required: false
    },
    menu: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    inventory: {
      type: Object,
      required: false
    },
  },
  data () {
    return {
      toggle: false,
      formData: {
        type: this.type,
        name: '',
        unitId: null
      },
      sourceItemFormData: {
        type: 'sourceItem',
        parentId: this.item.id,
        inventoryId: null,
        qty: 0
      },
      sourceExpenseFormData: {
        type: 'sourceExpense',
        parentId: this.item.id,
        accountId: null,
        costAccountId: null
      },
      transferItemFormData: {
        type: 'transferItem',
        parentId: this.item.id,
        inventoryId: null,
        accountId: null,
        itemAccountId: null,
        qty: 0,
        amountRatio: ''
      },
      transferExpenseFormData: {
        type: 'transferExpense',
        parentId: this.item.id,
        accountId: null,
        expenseAccountId: null,
        amountRatio: ''
      },
      tabs: [
        {text: 'นำเข้าสินค้า', value: 'sourceItem'},
        {text: 'ต้นทุนสินค้า', value: 'sourceExpense'},
        {text: 'ไปยังสินค้า', value: 'transferItem'},
        {text: 'ไปยังค่าใช้จ่าย', value: 'transferExpense'},
      ],
      selectedTab: 'sourceItem'
    }
  },
  validations: {
    formData: {
      name: {required},
      unitId: {required},
    },
    sourceItemFormData: {
      inventoryId: {required},
      qty: {required},
    },
    sourceExpenseFormData: {
      costAccountId: {required}
    },
    transferItemFormData: {
      inventoryId: {required},
      itemAccountId: {required},
      qty: {required},
      amountRatio: {
        required,
        minValue: (value) => value >= 0
      }
    },
    transferExpenseFormData: {
      expenseAccountId: {required},
      amountRatio: {
        required,
        minValue: (value) => value > 0
      }
    }
  },
  computed: {
    sourceItems () {
      return this.item.children.filter(v => v.type === 'sourceItem')
    },
    sourceExpenses () {
      return this.item.children.filter(v => v.type === 'sourceExpense')
    },
    transferItems () {
      return this.item.children.filter(v => v.type === 'transferItem')
    },
    transferExpenses () {
      return this.item.children.filter(v => v.type === 'transferExpense')
    }
  },
  methods: {
    serializeInput (formData) {
      return formData
    },
    serializeSourceExpenseInput (formData) {
      const obj = {
        ...formData,
        junctionChildren: []
      }
      delete obj.costAccountId

      if (formData.costAccountId) {
        obj.junctionChildren.push({
          tag: 'costAccountId',
          childId: formData.costAccountId
        })
      }

      return obj
    },
    serializeTransferItemInput (formData) {
      const obj = {
        ...formData,
        junctionChildren: []
      }
      delete obj.itemAccountId

      if (formData.itemAccountId) {
        obj.junctionChildren.push({
          tag: 'itemAccountId',
          childId: formData.itemAccountId
        })
      }

      return obj
    },
    serializeTransferExpenseInput (formData) {
      const obj = {
        ...formData,
        junctionChildren: []
      }
      delete obj.expenseAccountId

      if (formData.expenseAccountId) {
        obj.junctionChildren.push({
          tag: 'expenseAccountId',
          childId: formData.expenseAccountId
        })
      }

      return obj
    },
    updateData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$emit('update', {
        id: this.item.id,
        input: this.serializeInput(this.formData),
        cb: () => {
          this.toggle = false
          this.$v.formData.$reset()
        }
      })
    },
    destroyData (id, parentId) {
      this.$emit('destroy', {id, parentId})
    },
    cancelData () {
      this.toggle = false
      this.$emit('cancel', true)
    },
    addSourceItem () {
      this.$v.sourceItemFormData.$touch()
      if (this.$v.sourceItemFormData.$invalid) return

      this.$emit('child', {
        input: this.serializeInput(this.sourceItemFormData),
        cb: () => {
          this.toggle = true
          this.$v.sourceItemFormData.$reset()
        }
      })
    },
    addSourceExpense () {
      this.$v.sourceExpenseFormData.$touch()
      if (this.$v.sourceExpenseFormData.$invalid) return

      this.$emit('child', {
        input: this.serializeSourceExpenseInput(this.sourceExpenseFormData),
        cb: () => {
          this.toggle = true
          this.$v.sourceExpenseFormData.$reset()
        }
      })
    },
    addTransferItem () {
      this.$v.transferItemFormData.$touch()
      if (this.$v.transferItemFormData.$invalid) return

      this.$emit('child', {
        input: this.serializeTransferItemInput(this.transferItemFormData),
        cb: () => {
          this.toggle = true
          this.$v.transferItemFormData.$reset()
        }
      })
    },
    addTransferExpense () {
      this.$v.transferExpenseFormData.$touch()
      if (this.$v.transferExpenseFormData.$invalid) return

      this.$emit('child', {
        input: this.serializeTransferExpenseInput(this.transferExpenseFormData),
        cb: () => {
          this.toggle = true
          this.$v.transferExpenseFormData.$reset()
        }
      })
    },
  },
  watch: {
    toggle (value) {
      if (value && !this.isForm) {
        this.formData.name = this.item.name
        this.formData.unitId = this.item.unitId
        this.$nextTick(() => {

        })
      } if (value && this.isForm) {
        this.$nextTick(() => {

        })
      }
    },
  },
  created () {
    if (this.isForm) {
      this.toggle = true
    }
  },
  components: {
    DocConfigFormInventory,
    DocConfigFormAccount,
    DocConfigFormUnit,
    DocConfigFormCostAccount,
    DocConfigFormItemAccount,
    DocConfigFormExpenseAccount,
  }
}
</script>

<style lang="css" scoped>
</style>
